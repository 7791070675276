import React, {useEffect} from "react"


const Login = () => {
  useEffect(() => {
    window.location.href = 'https://appleid.apple.com/auth/authorize?response_type=code%20id_token&response_mode=form_post&client_id=co.swifttips.swifttips&redirect_uri=https%3A%2F%2Fsecure.ziptips.io%2Fauth%2Fcallback&scope=name%20email'; 
  })
   return (
     <div>
    
     </div>
  )
}
export default Login;